
import { Component, Prop, Vue } from 'vue-property-decorator';
import { MaterialGroup } from '@/models/materialGroup';
import { materialsService } from '@/services/materials.service';
import { Material } from '@/models/material';

@Component
export default class AssignMaterialToGroup extends Vue {
  @Prop({ required: true })
  public materialGroups!: MaterialGroup[];

  @Prop({ required: true })
  public material!: Material;

  public materialGroupId = 0;

  public async onSubmit() {
    this.$spinner.showSpinner();
    this.material.materialGroupId = this.materialGroupId;

    materialsService
      .update(this.material)
      .then(() => {
        this.closeModal();
      })
      .finally(() => this.$spinner.removeSpinner());
  }

  private closeModal() {
    this.$emit('close');
  }
}
