
import { Component, Prop, Vue } from 'vue-property-decorator';
import TableActionsList from './TableActionsList.vue';

@Component({
  components: {
    TableActionsList,
  },
})
export default class TableColumnActionsWrapper extends Vue {
  @Prop({ required: true })
  private row!: any[];
}
