// --------------------------------------------------------------------------------
// <copyright file="pieChartKpiWidgetGenerator.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { formatPercentage } from '@/utils/number';
import { EChartsOption } from 'echarts';
import { DataWithTimeSpan } from '../chartsData';
import { GeneratorParams } from '../generatorParams';
import { AbstractKpiWidgetGenerator } from './abstractKpiWidgetGenerator';

export abstract class PieChartKpiWidgetGenerator<
  T extends DataWithTimeSpan,
> extends AbstractKpiWidgetGenerator<T> {
  protected abstract getMaxDataEntry(primaryData: T[]): number;

  override updateOptions(
    data: T[],
    parameters: GeneratorParams,
    prevOptions?: EChartsOption,
  ): EChartsOption {
    const primaryData = data.filter((x) => x.isprimary);
    const maxDataEntry = this.getMaxDataEntry(primaryData);
    const palette = this.generatePalette(primaryData.length);

    return {
      tooltip: {
        show: true,
        formatter: (params: any) => {
          const { name, value, percent } = params;
          return `${name}: <b>${this.formatWithUnit(value)}</b> (${formatPercentage(percent)})`;
        },
      },
      series: [
        {
          type: 'pie',
          radius: ['55%', '85%'],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: 'center',
          },
          labelLine: {
            show: false,
          },
          data: this.getSeriesData(primaryData)
            .sort((a, b) => a.value - b.value)
            .map((series, index) => ({
              ...series,
              itemStyle: {
                color: palette[index],
              },
            })),
        },
      ],
      graphic: [
        {
          type: 'group',
          top: 'center',
          left: 'center',
          children: [
            {
              type: 'text',
              bottom: '80%',
              left: 'center',
              style: {
                text: `${this.formatWithUnit(maxDataEntry)}`,
                font: '20px Arial',
              },
            },
            {
              type: 'text',
              top: '80%',
              left: 'center',
              style: {
                text: 'Top 1',
                fill: this.mainColor,
                font: 'bold 16px Inter',
              },
            },
          ],
        },
      ],
    };
  }
}
