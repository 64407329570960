// --------------------------------------------------------------------------------
// <copyright file="bendGanttChartTableDataRetriever.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { DataRetriever, ProcedureName } from '../abstract/chartGenerator';
import { FilterTimeAxisSpanEnum } from '@/models/enums/FilterTimeAxisSpanEnum';
import { FilterTimeSpanEnum } from '@/models/enums/FilterTimeSpanEnum';
import {
  BEND_GANTT_PRODUCTION_MODES,
  BendGanttSpeedFlowStates,
  BendIntradayTableData,
  FlowSpeedGanttData,
} from '../chartsData';
import {
  mockGanttTableData,
  mockSheetPartsData,
  mockFlowSpeedGanttData,
} from '../mockWidgetSelectorData';
import { getDefaultZoomValues } from '@/utils/gantt';

export class BendGanttChartTableDataRetriever extends DataRetriever<BendIntradayTableData> {
  constructor(procedure: ProcedureName, private tenantIdDh: number) {
    super(procedure);
  }

  override async getData(
    selectedDevices: string[],
    selectedShifts: number[],
    timeSpan: [string, string] | FilterTimeSpanEnum,
    timeAxisSpan?: FilterTimeAxisSpanEnum | undefined,
    params?: { [key: string]: any } | undefined,
    customerNo?: string | undefined,
  ): Promise<BendIntradayTableData> {
    const data = this.getMockData();
    // We want to get the space of time that has not yet arrived from the machine because the data is being processed in Flow Speed
    // Gets the endTimestamp of the last object in the data (the last one because it is sorted).
    selectedDevices.forEach((device) => {
      const lastData = this.getLastStateFlowSpeedByDevice(device, data.flowSpeedData);
      const lastDataEndTime = lastData.endTimestamp;
      // Gets the maximum value of the limits of the graph (converted to seconds)
      const chartEndTime = new Date(getDefaultZoomValues()[1]).getTime() / 1000;
      // If there is a gap between the last endTimestamp of the data and the limit of the graph
      if (chartEndTime > lastDataEndTime) {
        const deviceStateData: FlowSpeedGanttData = {
          deviceid: lastData.deviceid,
          startTimestamp: lastDataEndTime + 1,
          endTimestamp: chartEndTime,
          flowSpeed: 0,
          state: BendGanttSpeedFlowStates.Processing,
        };
        data.flowSpeedData.push(deviceStateData);
      }
    });

    return data;
  }

  override getMockData(): BendIntradayTableData {
    return {
      productionModeData: mockGanttTableData(BEND_GANTT_PRODUCTION_MODES),
      sheetPartsData: mockGanttTableData(mockSheetPartsData()),
      flowSpeedData: mockFlowSpeedGanttData(),
    };
  }

  private getLastStateFlowSpeedByDevice(
    deviceid: string,
    data: FlowSpeedGanttData[],
  ): FlowSpeedGanttData {
    const deviceData = data.filter((d) => d.deviceid === deviceid);
    return deviceData[deviceData.length - 1];
  }
}
