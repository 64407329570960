
import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import CreateMaterialGroup from '@/components/materialGroups/CreateMaterialGroup.vue';
import ManageMaterialGroup from '@/components/materialGroups/ManageMaterialGroup.vue';
import { materialsService } from '@/services/materials.service';
import { MaterialGroup } from '@/models/materialGroup';
import { Tenant } from '@/models/tenant';
import { materialGroupsService } from '@/services/materialGroups.service';
import { Material } from '@/models/material';
import AssignMaterialToGroup from '@/components/materialGroups/AssignMaterialToGroup.vue';
import TableActionButton from '@/components/tableWithActions/TableActionButton.vue';
import TableWithActions from '@/components/tableWithActions/TableWithActions.vue';

@Component({
  components: {
    TableActionButton,
    TableWithActions,
  },
})
export default class MaterialGroups extends Vue {
  @Prop({ required: true, default: MaterialGroup.GetEmpty() })
  private tenant!: Tenant;

  @Ref('assignMaterial')
  private assignMaterial!: AssignMaterialToGroup;

  private materialGroups: MaterialGroup[] = [];
  private materials: Material[] = [];

  private created() {
    this.refreshData();
  }

  private async refreshData() {
    await this.getMaterialGroups();
    await this.getMaterials();
  }

  private async startAssignMaterialToGroup(material: Material) {
    this.$buefy.modal.open({
      parent: this,
      component: AssignMaterialToGroup,
      hasModalCard: true,
      trapFocus: true,
      props: {
        materialGroups: this.knownMaterialGroups,
        material,
      },
      events: {
        close: () => this.refreshData(),
      },
    });
  }

  private confirmDelete(id: number, name: string): void {
    this.$buefy.dialog.confirm({
      title: this.$i18n.t('dialog.confirm_delete_title').toString(),
      message: this.$i18n
        .t('confirmation.message', {
          action: this.$i18n.t('action.delete').toString().toLowerCase(),
          entity: this.$i18n.t('material_group.name').toString().toLowerCase(),
          name,
        })
        .toString(),
      confirmText: this.$i18n.t('dialog.confirm_delete').toString(),
      cancelText: this.$i18n.t(`dialog.confirm_cancel`).toString(),
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.deleteMaterialGroup(id),
    });
  }

  private get unassignedMaterials(): Material[] {
    const unassignedGroupsIds = this.materialGroups.filter((g) => g.isUnknown).map((g) => g.id);
    return this.materials.filter((m) => unassignedGroupsIds.includes(m.materialGroupId));
  }

  private get knownMaterialGroups(): MaterialGroup[] {
    return this.materialGroups.filter((group) => !group.isUnknown);
  }

  private createMaterialGroup() {
    this.$buefy.modal.open({
      parent: this,
      component: CreateMaterialGroup,
      hasModalCard: true,
      trapFocus: true,
      props: {
        tenant: this.tenant,
      },
      events: {
        close: () => this.refreshData(),
      },
    });
  }

  private deleteMaterialGroup(id: number): void {
    this.$spinner.showSpinner();
    materialGroupsService
      .delete(id)
      .then(() => {
        this.refreshData();
      })
      .finally(() => {
        this.$spinner.removeSpinner();
      });
  }

  private async getMaterialGroups() {
    this.$spinner.showSpinner();
    materialGroupsService
      .get(this.tenant.id)
      .then((data: MaterialGroup[]) => {
        this.materialGroups = data;
      })
      .finally(() => this.$spinner.removeSpinner());
  }

  private async getMaterials() {
    this.$spinner.showSpinner();
    this.materials = await materialsService
      .get(this.tenant.id)
      .finally(() => this.$spinner.removeSpinner());
  }

  private manageMaterial(materialGroupId: number) {
    this.$buefy.modal.open({
      parent: this,
      component: ManageMaterialGroup,
      hasModalCard: true,
      trapFocus: true,
      props: {
        tenant: this.tenant,
        materialGroupId,
        materialGroups: this.materialGroups,
      },
      events: {
        close: () => this.refreshData(),
      },
    });
  }
}
