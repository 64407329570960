// --------------------------------------------------------------------------------
// <copyright file="signalr.plugin.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import Store from '@/store';
import { SignalrService } from '@/library-services/signalr.service';
import i18n from '@/i18n';

export const signalrPlugin = {
  install(vue: any, options: any) {
    vue.prototype.$signalr = new SignalrService(Store, i18n);
  },
};
