// --------------------------------------------------------------------------------
// <copyright file="widgetFactory.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import i18n from '@/i18n';
import { PieChartGenerator } from './pieChartGenerator';
import { DetailedViewEnum, WidgetEnum } from '../enums/WidgetEnum';
import { ChartGenerator, DataRetriever } from './abstract/chartGenerator';
import { GaugeChartGenerator } from './gaugeChartGenerator';
import { GroupedBarChartGenerator } from './groupedBarChartGenerator';
import { HorizontalBarChartGenerator } from './horizontalBarChartGenerator';
import { LineChartGenerator } from './lineChartGenerator';
import { OutputScrapChartGenerator } from './outputScrapChartGenerator';
import { FactoryOverviewGenerator } from './factoryOverviewGenerator';
import { GanttChartGenerator } from './ganttChartGenerator';
import { CumulativeLinesGenerator } from './cumulativeLinesGenerator';
import DummyChartGenerator from './dummyChartGenerator';
import BusinessLineChartGenerator from './businessLineChartGenerator';
import { QuotesConversionRatesGenerator } from './quotesConversionRatesGenerator';
import { TopQuotedMaterialsTimelineGenerator } from './topQuotedMaterialsTimelineGenerator';
import QuotesCustomerEvolutionGenerator from './quotesCustomerEvolutionGenerator';
import SalesCustomerGroupsGenerator from './salesCustomerGroupsGenerator';
import SalesTopBySalesGenerator from './salesTopBySalesGenerator';
import SalesFunnelGenerator from './salesFunnelGenerator';
import { CuttingTimePerGasGenerator } from './cuttingTimePerGasGenerator';
import CutStatesIntradayGenerator from './cutStatesIntradayGenerator';
import { SalesUnpaidDistributionGenerator } from './salesUnpaidDistributionGenerator';
import OpenSalesFunnelGenerator from './openSalesFunnelGenerator';
import { POTreemapGenerator } from './poTreeMapGenerator';
import { QuotesSizeGenerator } from './quotesSizeGenerator';
import { TemperaturesChartGenerator } from './temperaturesChartGenerator';
import { DrawerInformationTimelineGenerator } from './drawerInformationTimelineGenerator';
import { DrawerCycleTimesGenerator } from './drawerCycleTimesGenerator';
import { LaserUsedVSNotUsedTimesGenerator } from './laserUsedVSNotUsedTimesGenerator';
import { LaserPowerUtilizationTimesGenerator } from './laserPowerUtilizationTimesGenerator';
import { DriveCurrentTimelineGenerator } from './driveCurrentTimelineGenerator';
import { DriveCurrentDifferenceHistogramGenerator } from './driveCurrentDifferenceHistogramGenerator';
import { DriveAccumulatedCurrentDifferenceTimelineGenerator } from './driveAccumulatedCurrentDifferenceTimelineGenerator';
import { POLStatusDueDateGenerator } from './polStatusDueDateGenerator';
import { FinishedPOEstimatedVsActualPOLGenerator } from './finishedPOEstimatedVsActualPOLGenerator';
import { DrawerOverviewCycleTimesGenerator } from './drawerOverviewCycleTimesGenerator';
import { CuttingHoursTimelineGenerator } from './cuttingHoursTimelineGenerator';
import { LaserConsumptionOverviewTimelineGenerator } from './laserConsumptionOverviewTimelineGenerator';
import QuotesAbcCustomersOverviewGenerator from './quotesAbcCustomersOverviewGenerator';
import { QuotedMaterialsListGenerator } from './quotedMaterialsListGenerator';
import { getTenantMeasurementId, weightUnit } from '@/utils/measurement';
import { GeneratorParams } from './generatorParams';
import { LaserOutputAndScrapTimelineGenerator } from './laserOutputAndScrapTimelineGenerator';
import { POFinishedDueDateGenerator } from './poFinishedDueDateGenerator';
import { AmountVsNumberOfQuotesBySalespeopleGenerator } from './amountVsNumberOfQuotesBySalespeopleGenerator';
import { LaserCuttingTimeData, LaserOEEData, SalesTopBySalesData } from './chartsData';
import { Logger } from '@/utils/logger';
import { SalesPerSalespersonTimelineGenerator } from './salesPerSalespersonTimelineGenerator';
import { TopSalespeopleDetailGenerator } from './topSalespeopleDetailGenerator';
import { EventTypeByTimeGenerator } from './eventTypeByTimeGenerator';
import { LaserInstantConsumptionTimelineGenerator } from '@/models/Charts/laserInstantConsumptionTimelineGenerator';
import { LaserAvailableVsUsedTimelineGenerator } from '@/models/Charts/laserAvailableVsUsedTimelineGenerator';
import { LaserAccumulatedConsumptionTimelineGenerator } from '@/models/Charts/laserAccumulatedConsumptionTimelineGenerator';
import { Measurements } from '../enums/Measurement';
import { MachineType } from '../enums/MachineType';
import { EventTypeByModuleGenerator } from './eventTypeByModuleGenerator';
import { HealthTimelineGenerator } from '@/models/Charts/healthTimelineGenerator';
import { HealthIndicatorsRetriever } from '@/models/Charts/slotted/healthIndicatorsRetriever';
import { DiodesAndDrawerCyclesTimelineGenerator } from '@/models/Charts/diodesAndDrawerCyclesTimelineGenerator';
import { BendPerformanceRetriever } from './slotted/bendPerformanceRetriever';
import { AlertOccurrenceTableRetriever } from './slotted/alertOccurrenceTableRetriever';
import { CareDeviceOverviewRetriever } from './slotted/careDeviceOverviewRetriever';
import { CareOverviewHealthIndicatorsRetriever } from '@/models/Charts/slotted/CareOverviewHealthIndicatorsRetriever';
import { CareOverviewModuleEventsIndicatorsRetriever } from '@/models/Charts/slotted/careOverviewModuleEventsIndicatorsRetriever';
import { LaserPowerUtilizationHistogramGenerator } from '@/models/Charts/laserPowerUtilizationHistogramGenerator';
import { QuotesConversionRatesDetailedGenerator } from '@/models/Charts/quotesConversionRatesDetailedGenerator';
import { QuotesFiguresRetriever } from './slotted/quotesFiguresRetriever';
import { ManufacturingFiguresRetriever } from './slotted/manufacturingFiguresRetriever';
import { TechnologyOverviewFiguresRetriever } from './slotted/technologyOverviewFiguresRetriever';
import { BusinessFiguresRetriever } from './slotted/businessFiguresRetriever';
import { SalesFiguresRetriever } from './slotted/salesFiguresRetriever';
import { DeviceStatusRetriever } from './slotted/deviceStatusRetriever';
import QuotesMapGenerator from '../Maps/quotesMapGenerator';
import MapGenerator from '../Maps/mapGenerator';
import { LaserGanttChartTableGenerator } from './gantt/laserGanttChartTableGenerator';
import { TimeBetweenBendKpiWidgetGenerator } from './timeBetweenBendKpiWidgetGenerator';
import { BendingAvailabilityKpiWidgetGenerator } from './bendingAvailabilityKpiWidgetGenerator';
import { BendingAvailabilityBarChartKpiWidgetGenerator } from './bendingAvailabilityBarChartKpiWidgetGenerator';
import { CutCurrentWorkloadTableDataRetriever } from './slotted/cutCurrentWorkloadTableDataRetriever';
import { EventsTableRetriever } from './slotted/eventsTableRetriever';
import { BendGanttChartTableDataRetriever } from './gantt/bendGanttChartTableDataRetriever';
import BendProductivityFiguresChartGenerator from '@/models/Charts/bendProductivityFiguresChartGenerator';

export default class WidgetFactory {
  static createGenerator(
    widget: WidgetEnum | DetailedViewEnum,
    tenantIdDh: number,
  ): ChartGenerator<any> {
    switch (widget) {
      // WCC
      // Cut
      case WidgetEnum.LaserAvailability:
        return new HorizontalBarChartGenerator(widget, tenantIdDh);
      case WidgetEnum.LaserProductivity:
        return new PieChartGenerator(widget, tenantIdDh);
      case WidgetEnum.LaserProductivityHist:
        return new LineChartGenerator(widget, tenantIdDh);
      case WidgetEnum.LaserOee:
        return new GaugeChartGenerator<LaserOEEData>(widget, tenantIdDh);
      case WidgetEnum.LaserStarveBlock:
        return new HorizontalBarChartGenerator(widget, tenantIdDh);
      case WidgetEnum.LaserOutputScrap:
        return new OutputScrapChartGenerator(widget, tenantIdDh);
      case WidgetEnum.LaserCuttingTime:
        return new GaugeChartGenerator<LaserCuttingTimeData>(widget, tenantIdDh);
      case WidgetEnum.LaserAvailabilityHist:
        return new LineChartGenerator(widget, tenantIdDh);
      case WidgetEnum.LaserOeeHist:
        return new LineChartGenerator(widget, tenantIdDh);
      case WidgetEnum.LaserCuttingTimeHist:
        return new LineChartGenerator(widget, tenantIdDh);
      case WidgetEnum.LaserStarveBlockHist:
        return new LineChartGenerator(widget, tenantIdDh);
      case WidgetEnum.LaserOutputAndScrapTimeline:
        return new LaserOutputAndScrapTimelineGenerator(widget, tenantIdDh);
      case WidgetEnum.LaserStatesGantt:
        return new GanttChartGenerator(widget, tenantIdDh);
      case WidgetEnum.LaserStatesGanttTable:
        return new LaserGanttChartTableGenerator(widget, tenantIdDh);

      // Laser detailed views
      case DetailedViewEnum.CutStatesIntraday:
        return new CutStatesIntradayGenerator(widget, tenantIdDh);
      case DetailedViewEnum.IntradayOeeTime:
        return new GanttChartGenerator(widget, tenantIdDh);
      case DetailedViewEnum.CumulativeCuttingTime:
        return new CumulativeLinesGenerator(widget, tenantIdDh);
      case DetailedViewEnum.CumulativeCuttingTimePerShift:
        return new CumulativeLinesGenerator(widget, tenantIdDh);
      case DetailedViewEnum.LaserTodaysGas:
        return new CuttingTimePerGasGenerator(widget, tenantIdDh);

      // Care
      case WidgetEnum.TechnologyOverviewCuttingHoursTimeline:
        return new CuttingHoursTimelineGenerator(widget, tenantIdDh);
      case WidgetEnum.TechnologyOverviewLaserConsumptionTimeline:
        return new LaserConsumptionOverviewTimelineGenerator(widget, tenantIdDh);
      case WidgetEnum.TechnologyOverviewDrawerCycleTimes:
        return new DrawerOverviewCycleTimesGenerator(widget, tenantIdDh);
      case WidgetEnum.TechnologyTemperatures:
        return new TemperaturesChartGenerator(widget, tenantIdDh);
      case WidgetEnum.TechnologyDrawerInformationTimeline:
        return new DrawerInformationTimelineGenerator(widget, tenantIdDh);
      case WidgetEnum.TechnologyDrawerCycleTimes:
        return new DrawerCycleTimesGenerator(widget, tenantIdDh);
      case WidgetEnum.TechnologyLaserConsumptionTimeline:
        return new LaserAccumulatedConsumptionTimelineGenerator(widget, tenantIdDh);
      case WidgetEnum.TechnologyLaserUsedVsNotUsedTimes:
        return new LaserUsedVSNotUsedTimesGenerator(widget, tenantIdDh);
      case WidgetEnum.TechnologyLaserPowerUtilizationTimes:
        return new LaserPowerUtilizationTimesGenerator(widget, tenantIdDh);
      case WidgetEnum.TechnologyDriveCurrentTimeline:
        return new DriveCurrentTimelineGenerator(widget, tenantIdDh);
      case WidgetEnum.TechnologyDriveCurrentDifferenceHistogram:
        return new DriveCurrentDifferenceHistogramGenerator(widget, tenantIdDh);
      case WidgetEnum.TechnologyDriveAccumulatedCurrentDifferenceTimeline:
        return new DriveAccumulatedCurrentDifferenceTimelineGenerator(widget, tenantIdDh);

      // Care console
      case WidgetEnum.CareHealthTimeline:
        return new HealthTimelineGenerator(widget, tenantIdDh);
      case WidgetEnum.CareEventsTimeline:
        return new EventTypeByTimeGenerator(widget, tenantIdDh);
      case WidgetEnum.CareEventsByModule:
        return new EventTypeByModuleGenerator(widget, tenantIdDh);
      case WidgetEnum.CareCuttingHeadDiodesAndDrawerCyclesTimeline:
        return new DiodesAndDrawerCyclesTimelineGenerator(widget, tenantIdDh);
      case WidgetEnum.CareCuttingHeadEventsTimeline:
        return new EventTypeByTimeGenerator(WidgetEnum.CareEventsTimeline, tenantIdDh);
      case WidgetEnum.CareCuttingHeadTemperatures:
        return new LineChartGenerator(widget, tenantIdDh);
      case WidgetEnum.CareLaserConsumptionLaserConsumptionTimeline:
        return new LaserInstantConsumptionTimelineGenerator(widget, tenantIdDh);
      case WidgetEnum.CareLaserConsumptionPowerUtilizationTimeline:
        return new LaserPowerUtilizationHistogramGenerator(
          WidgetEnum.TechnologyLaserPowerUtilizationTimes,
          tenantIdDh,
        );
      case WidgetEnum.CareLaserConsumptionAvailableVsUsedTimeline:
        return new LaserAvailableVsUsedTimelineGenerator(widget, tenantIdDh);

      // Bend
      case WidgetEnum.BendingAvailability:
        return new HorizontalBarChartGenerator(widget, tenantIdDh);
      case WidgetEnum.BendingTimeBetweenBend:
        return new GroupedBarChartGenerator(widget, tenantIdDh);
      case WidgetEnum.BendingTopParts:
        return new GroupedBarChartGenerator(widget, tenantIdDh);
      case WidgetEnum.BendingPerformancePart:
        return new LineChartGenerator(widget, tenantIdDh);
      case WidgetEnum.BendingAvailabilityHist:
        return new LineChartGenerator(widget, tenantIdDh);
      case WidgetEnum.BendingTimeBetweenBendHist:
        return new LineChartGenerator(widget, tenantIdDh);
      case WidgetEnum.BendingPerformancePartHist:
        return new LineChartGenerator(widget, tenantIdDh);
      case WidgetEnum.BendingPerformanceHist:
        return new LineChartGenerator(widget, tenantIdDh);
      case WidgetEnum.BendingNumberOfBendsTimeline:
        return new LineChartGenerator(widget, tenantIdDh);
      case WidgetEnum.BendProductivityFigures:
        return new BendProductivityFiguresChartGenerator(widget);

      // Tube
      case WidgetEnum.TubeAvailability:
        return new HorizontalBarChartGenerator(widget, tenantIdDh);
      case WidgetEnum.TubeProductivity:
        return new PieChartGenerator(widget, tenantIdDh);
      case WidgetEnum.TubeOee:
        return new GaugeChartGenerator<LaserOEEData>(widget, tenantIdDh);
      case WidgetEnum.TubeStarveBlock:
        return new HorizontalBarChartGenerator(widget, tenantIdDh);
      case WidgetEnum.TubeOutputScrap:
        return new OutputScrapChartGenerator(widget, tenantIdDh);
      case WidgetEnum.TubeCuttingTime:
        return new GaugeChartGenerator<LaserCuttingTimeData>(widget, tenantIdDh);
      case WidgetEnum.TubeAvailabilityHist:
        return new LineChartGenerator(widget, tenantIdDh);
      case WidgetEnum.TubeProductivityHist:
        return new LineChartGenerator(widget, tenantIdDh);
      case WidgetEnum.TubeOeeHist:
        return new LineChartGenerator(widget, tenantIdDh);
      case WidgetEnum.TubeCuttingTimeHist:
        return new LineChartGenerator(widget, tenantIdDh);
      case WidgetEnum.TubeStarveBlockHist:
        return new LineChartGenerator(widget, tenantIdDh);
      case WidgetEnum.TubeOutputScrapHist:
        return new LaserOutputAndScrapTimelineGenerator(widget, tenantIdDh);
      case WidgetEnum.TubeStatesGantt:
        return new GanttChartGenerator(widget, tenantIdDh);

      // Tube detailed views
      case DetailedViewEnum.TubesCuttingTimePerGas:
        return new CuttingTimePerGasGenerator(widget, tenantIdDh);
      case DetailedViewEnum.TubesCumulativeCuttingTime:
        return new CumulativeLinesGenerator(widget, tenantIdDh);
      case DetailedViewEnum.TubesCumulativeCuttingTimePerShift:
        return new CumulativeLinesGenerator(widget, tenantIdDh);

      // Generic
      case WidgetEnum.FactoryOverview:
        return new FactoryOverviewGenerator(widget, tenantIdDh);

      // BC
      // Overview
      case WidgetEnum.OpenSalesFunnel:
        return new OpenSalesFunnelGenerator(widget, tenantIdDh);

      // Quotes
      case WidgetEnum.QuotesConvertedAmountTimeline:
        return new BusinessLineChartGenerator(widget, tenantIdDh);
      case WidgetEnum.QuotesAbcCustomers:
        return new QuotesAbcCustomersOverviewGenerator(widget, tenantIdDh);
      case WidgetEnum.QuotesConversionRates:
        return new QuotesConversionRatesGenerator(widget, tenantIdDh);
      case WidgetEnum.QuotesConversionRatesGauge:
        return new QuotesConversionRatesDetailedGenerator(widget, tenantIdDh);
      case WidgetEnum.QuotesCustomerEvolution:
        return new QuotesCustomerEvolutionGenerator(widget, tenantIdDh);
      case WidgetEnum.QuotesSize:
        return new QuotesSizeGenerator(widget, tenantIdDh);
      case WidgetEnum.QuotesTopQuotedMaterialsTimeline:
        return new TopQuotedMaterialsTimelineGenerator(widget, tenantIdDh);
      case WidgetEnum.QuotesQuotedMaterialsList:
        return new QuotedMaterialsListGenerator(widget, tenantIdDh);
      case WidgetEnum.QuotesTopSalespeopleDetailed:
        // It's the same chart that appears in Sales, so same generator and procedure.
        return new SalesTopBySalesGenerator(WidgetEnum.SalesTopSalespeople, tenantIdDh);

      // Sales
      case WidgetEnum.SalesCustomerGroups:
        return new SalesCustomerGroupsGenerator(widget, tenantIdDh);
      case WidgetEnum.SalesTopSalespeople:
        return new SalesTopBySalesGenerator(widget, tenantIdDh);
      case WidgetEnum.AmountVsNumberOfQuotesBySalesperson:
        return new AmountVsNumberOfQuotesBySalespeopleGenerator(
          WidgetEnum.SalesTopSalespeople, // Uses the same procedure
          tenantIdDh,
        );
      case WidgetEnum.SalesAmountBySalesperson:
      case WidgetEnum.SalesItemChangesBySalesperson:
      case WidgetEnum.NumberOfSalesBySalesperson:
        return new TopSalespeopleDetailGenerator(
          WidgetEnum.SalesTopSalespeople, // All use the same procedure
          tenantIdDh,
        );
      case WidgetEnum.SalesPerSalespersonTimeline:
        return new SalesPerSalespersonTimelineGenerator(widget, tenantIdDh);
      case WidgetEnum.SalesFunnel:
        return new SalesFunnelGenerator(widget, tenantIdDh);

      case WidgetEnum.SalesTimeline:
        return new BusinessLineChartGenerator(widget, tenantIdDh);
      case WidgetEnum.SalesUnpaidDistribution:
        return new SalesUnpaidDistributionGenerator(widget, tenantIdDh);

      // SFC
      // Manufacturing
      case WidgetEnum.ManufacturingPOStatus:
        return new POTreemapGenerator(widget, tenantIdDh);
      case WidgetEnum.ManufacturingReleasedPOStatus:
        return new POTreemapGenerator(widget, tenantIdDh);
      case WidgetEnum.ManufacturingPOFinishedDueDate:
        return new POFinishedDueDateGenerator(widget, tenantIdDh);
      case WidgetEnum.ManufacturingPOLStatusDueDate:
        return new POLStatusDueDateGenerator(widget, tenantIdDh);
      case WidgetEnum.ManufacturingFinishedPOEstimatedVsActualPOL:
        return new FinishedPOEstimatedVsActualPOLGenerator(widget, tenantIdDh);

      // Dummy
      case WidgetEnum.Dummy:
        return new DummyChartGenerator(widget);
      case WidgetEnum.TimeBetweenBendKpiWidget:
        return new TimeBetweenBendKpiWidgetGenerator(widget, tenantIdDh);
      case WidgetEnum.BendingAvailabilityKpiWidget:
        return new BendingAvailabilityKpiWidgetGenerator(widget, tenantIdDh);
      case WidgetEnum.BendingAvailabilityBarChartKpiWidget:
        return new BendingAvailabilityBarChartKpiWidgetGenerator(
          WidgetEnum.BendingAvailabilityKpiWidget,
          tenantIdDh,
        );

      default:
        throw new Error(`Generator not found for widget key: ${widget}`);
    }
  }

  static createMapGenerator(
    widget: WidgetEnum | DetailedViewEnum,
    tenantIdDh: number,
  ): MapGenerator {
    switch (widget) {
      case WidgetEnum.QuotesMap:
      case WidgetEnum.SalesMap:
        return new QuotesMapGenerator(widget, tenantIdDh);
      case WidgetEnum.QuotesConvertedMap:
        return new QuotesMapGenerator(WidgetEnum.QuotesMap, tenantIdDh, 'converted_amount');
      default:
        throw new Error(`Map generator not found for widget key: ${widget}`);
    }
  }

  static createDataRetriever(
    widget: WidgetEnum | DetailedViewEnum,
    tenantIdDh: number,
  ): DataRetriever<any> {
    switch (widget) {
      case WidgetEnum.CareHealthIndicators:
        return new HealthIndicatorsRetriever(widget, tenantIdDh);
      case WidgetEnum.CareOverviewDevice:
        return new CareDeviceOverviewRetriever(widget, tenantIdDh);
      case WidgetEnum.CareOverviewHealthIndicators:
        return new CareOverviewHealthIndicatorsRetriever(widget, tenantIdDh);
      case WidgetEnum.CareOverviewModuleEventsIndicators:
        return new CareOverviewModuleEventsIndicatorsRetriever(widget, tenantIdDh);
      case WidgetEnum.BendingPerformance:
        return new BendPerformanceRetriever(widget, tenantIdDh);
      case WidgetEnum.CareAlertOccurrencesTable:
        return new AlertOccurrenceTableRetriever(widget);
      case WidgetEnum.QuotesFigures:
      case WidgetEnum.QuotesFiguresDetailed:
        return new QuotesFiguresRetriever(widget, tenantIdDh);
      case WidgetEnum.SalesFigures:
        return new SalesFiguresRetriever(widget, tenantIdDh);
      case WidgetEnum.ManufacturingFigures:
        return new ManufacturingFiguresRetriever(widget, tenantIdDh);
      case WidgetEnum.TechnologyOverviewSscFigures:
        return new TechnologyOverviewFiguresRetriever(widget, tenantIdDh);
      case WidgetEnum.BusinessOverviewFigures:
        return new BusinessFiguresRetriever(widget, tenantIdDh);
      case WidgetEnum.CutStatus:
      case WidgetEnum.BendStatus:
      case WidgetEnum.TubeStatus:
        return new DeviceStatusRetriever(widget, tenantIdDh);
      case WidgetEnum.LaserCurrentWorkload:
        return new CutCurrentWorkloadTableDataRetriever(widget, tenantIdDh);
      case WidgetEnum.EventsTable:
        return new EventsTableRetriever(widget);
      case WidgetEnum.EventsTableExpandedView:
        return new EventsTableRetriever(WidgetEnum.EventsTable);
      case WidgetEnum.BendStatesGanttTable:
        return new BendGanttChartTableDataRetriever(widget, tenantIdDh);
      default: {
        // To avoid repeating all cases from createGenerator here, we just call
        // it and ensure the returned object is really a DataRetriever.

        const providers = [this.createGenerator, this.createMapGenerator];

        for (const provider of providers) {
          try {
            const dataRetriever = provider(widget, tenantIdDh);
            if (this.isDataRetriever(dataRetriever)) {
              return dataRetriever;
            }
          } catch (e) {
            // Do nothing for the time being
            Logger.info(`Running provider for widget ${widget} failed`);
            continue;
          }
        }

        // If no generator or map generator is found, then we definitely did something wrong.
        // So better throw an exception
        Logger.error(`No DataRetriever has been found for ${widget}`);
        throw Error(`No DataRetriever has been found for ${widget}`);
      }
    }
  }

  private static isDataRetriever(object: any): object is DataRetriever<any> {
    return 'getData' in object && 'getMockData' in object && 'abort' in object;
  }

  static createOptions(widget: WidgetEnum | DetailedViewEnum): GeneratorParams {
    switch (widget) {
      case WidgetEnum.LaserAvailability:
      case WidgetEnum.TubeAvailability:
        return {
          xAxisName: 'h',
        };
      case WidgetEnum.TubeProductivity:
      case WidgetEnum.LaserProductivity:
        return {
          unit: ' h',
        };
      case WidgetEnum.TubeProductivityHist:
      case WidgetEnum.LaserProductivityHist:
        return {
          connectNulls: false,
          isLineChart: true,
          yAxisName: '%',
        };
      case WidgetEnum.QuotesConversionRatesGauge:
        return {
          unit: '%',
          maxValue: 100,
        };
      case WidgetEnum.LaserOee:
      case WidgetEnum.TubeOee:
        return {
          unit: '%',
          valueName: 'oee',
          maxValue: 100,
        };
      case WidgetEnum.LaserCuttingTime:
      case WidgetEnum.TubeCuttingTime:
        return {
          unit: ' h',
          valueName: 'cutting_time',
          maxValue: 100,
        };
      case WidgetEnum.LaserStarveBlock:
      case WidgetEnum.TubeStarveBlock:
        return {
          xAxisName: 'min',
        };
      case WidgetEnum.LaserOutputScrap:
        return {
          yAxisName: weightUnit(),
          // In BVC, the source data is already in the tenant's measurement system
          convertToLbs: false,
        };
      case WidgetEnum.TubeOutputScrap:
        return {
          yAxisName: weightUnit(),
          convertToLbs: getTenantMeasurementId() === Measurements.Imperial,
        };
      case WidgetEnum.BendingTimeBetweenBend:
        return {
          isPrimaryOnly: false,
          groupKey: 'category_name',
          valueKey: 'time_between_bend',
          xAxisName: 's',
        };
      case WidgetEnum.BendingAvailability:
        return {
          xAxisName: 'h',
        };
      case WidgetEnum.BendingTopParts:
        return {
          isPrimaryOnly: true,
          groupKey: 'PartName',
          valueKey: 'PartCount',
          metricName: 'top_parts',
        };
      case WidgetEnum.BendingPerformancePart:
        return {
          connectNulls: true,
          yAxisName: 'min/part',
        };
      case WidgetEnum.LaserAvailabilityHist:
      case WidgetEnum.TubeAvailabilityHist:
        return {
          connectNulls: false,
          isLineChart: true,
          yAxisName: 'h',
        };
      case WidgetEnum.LaserOeeHist:
      case WidgetEnum.TubeOeeHist:
        return {
          connectNulls: false,
          isLineChart: true,
          yAxisName: '%',
        };
      case WidgetEnum.LaserCuttingTimeHist:
      case WidgetEnum.TubeCuttingTimeHist:
        return {
          connectNulls: false,
          isLineChart: true,
          yAxisName: 'h',
        };
      case WidgetEnum.LaserStarveBlockHist:
      case WidgetEnum.TubeStarveBlockHist:
        return {
          connectNulls: false,
          isLineChart: true,
          yAxisName: 'min',
        };
      case WidgetEnum.LaserOutputAndScrapTimeline:
        return {
          upperLineName: 'output',
          lowerLineName: 'scrap',
          groupKey: 'group_name',
          isLineChart: true,
          yAxisName: weightUnit(),
          // In BVC, data is displayed in tenant's preferred system
          convertToLbs: false,
        };
      case WidgetEnum.TubeOutputScrapHist:
        return {
          upperLineName: 'output',
          lowerLineName: 'scrap',
          groupKey: 'group_name',
          isLineChart: true,
          yAxisName: weightUnit(),
          // In Tubes, unlike Cut, data is always sent in kgs
          convertToLbs: getTenantMeasurementId() === Measurements.Imperial,
        };
      case WidgetEnum.BendingAvailabilityHist:
        return {
          connectNulls: false,
          isLineChart: true,
          yAxisName: 'h',
        };
      case WidgetEnum.BendingTimeBetweenBendHist:
        return {
          connectNulls: false,
          isLineChart: true,
          yAxisName: 's',
        };
      case WidgetEnum.BendingPerformancePartHist:
        return {
          connectNulls: false,
          isLineChart: true,
          yAxisName: 'min/part',
        };
      case WidgetEnum.BendingPerformanceHist:
        return {
          connectNulls: false,
          isLineChart: true,
        };
      case WidgetEnum.BendingNumberOfBendsTimeline:
        return {
          isLineChart: true,
        };
      case DetailedViewEnum.CumulativeCuttingTime:
        return {
          isLineChart: true,
          yAxisName: 'h',
          valueKey: 'cut_time',
          title: i18n.t('report.cumulative_cutting_time_today').toString(),
        };
      case DetailedViewEnum.TubesCumulativeCuttingTime:
        return {
          isLineChart: true,
          yAxisName: 'h',
          valueKey: 'cut_time',
          title: i18n.t('report.cumulative_cutting_time_today').toString(),
          deviceType: MachineType.Tube,
        };
      case DetailedViewEnum.CumulativeCuttingTimePerShift:
        return {
          isLineChart: true,
          yAxisName: 'h',
          valueKey: 'cut_time',
          title: i18n.t('report.cumulative_cutting_time_by_shift_today').toString(),
        };
      case DetailedViewEnum.TubesCumulativeCuttingTimePerShift:
        return {
          isLineChart: true,
          yAxisName: 'h',
          valueKey: 'cut_time',
          title: i18n.t('report.cumulative_cutting_time_by_shift_today').toString(),
          deviceType: MachineType.Tube,
        };
      case DetailedViewEnum.LaserTodaysGas:
      case DetailedViewEnum.TubesCuttingTimePerGas:
        return {
          yAxisName: 'h',
          title: i18n.t('report.cutting_time_per_gas').toString(),
        };
      case WidgetEnum.CareCuttingHeadTemperatures:
        return {
          yAxisName: 'ºC',
        };
      case WidgetEnum.SalesAmountBySalesperson:
        return {
          valueKey: 'total_sales' as keyof SalesTopBySalesData,
        };
      case WidgetEnum.SalesItemChangesBySalesperson:
        return {
          valueKey: 'sales_item_changes' as keyof SalesTopBySalesData,
        };
      case WidgetEnum.NumberOfSalesBySalesperson:
        return {
          valueKey: 'number_of_sales' as keyof SalesTopBySalesData,
        };
      case WidgetEnum.TechnologyLaserPowerUtilizationTimes:
        return {
          yAxisName: 'h',
        };
      default:
        return {};
    }
  }
}
