// --------------------------------------------------------------------------------
// <copyright file="bendingAvailabilityKpiWidgetGenerator.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { FilterTimeSpanEnum } from '../enums/FilterTimeSpanEnum';
import { ProcedureName } from './abstract/chartGenerator';
import { metricsService } from '@/services/metrics.service';
import { DataWithTimeSpan } from './chartsData';
import { devicesService } from '@/services/devices.service';
import { PieChartKpiWidgetGenerator } from './abstract/pieChartKpiWidgetGenerator';
import { createPaletteByLightness } from '@/utils/color';

export interface BendingAvailabilityDataPerDevice extends DataWithTimeSpan {
  deviceId: string;
  bend: number;
  program: number;
  setup: number;
  warning: number;
  error: number;
}

export class BendingAvailabilityKpiWidgetGenerator extends PieChartKpiWidgetGenerator<BendingAvailabilityDataPerDevice> {
  constructor(procedure: ProcedureName, tenantIdDh: number) {
    const mainColor = '#df3918';
    const unit = 'h';
    super(procedure, tenantIdDh, mainColor, unit);
  }

  protected override generatePalette(amount: number): string[] {
    return createPaletteByLightness(this.mainColor, amount, 0.5, -0.5);
  }

  override async getData(
    selectedDevices: string[],
    selectedShifts: number[],
    timeSpan: FilterTimeSpanEnum | [string, string],
  ): Promise<BendingAvailabilityDataPerDevice[] | null> {
    return await metricsService.getDevicesMetrics<BendingAvailabilityDataPerDevice[]>(
      this.procedure,
      {
        tenantIdDh: this.tenantIdDh,
        deviceIds: selectedDevices,
        shifts: selectedShifts,
        timeSpan: timeSpan as FilterTimeSpanEnum,
      },
      this.controller,
    );
  }

  protected override getMaxDataEntry(primaryData: BendingAvailabilityDataPerDevice[]): number {
    return Math.max(...primaryData.map((x) => x.bend));
  }

  protected override getSeriesData(primaryData: BendingAvailabilityDataPerDevice[]): any[] {
    return primaryData.map((x) => ({
      name: devicesService.store.findByStringId(x.deviceId)!.name,
      value: x.bend,
    }));
  }
}
