
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';

import Wizard from './Wizard.vue';

@Component
export default class WizardStep extends Vue {
  @Prop({ required: true })
  title!: string;

  @Prop({ default: true })
  allowNextIf!: boolean;

  /**
   * Called when the wizard is leaving the step.
   *
   * For example because the user has clicked Next.
   *
   * @return true if the wizard can leave the step. Otherwise, the wizard will
   *   remain in this step.
   */
  @Prop({ default: () => () => true })
  onLeave!: () => boolean | Promise<boolean>;

  @Prop({ default: false })
  hidden!: boolean;

  @Inject()
  private $wizard?: Wizard;

  private created() {
    this.$wizard?.refreshSteps();
  }

  private beforeDestroy() {
    this.$wizard?.refreshSteps();
  }

  enter(fromPreviousPage: boolean) {
    this.$emit('enter', fromPreviousPage);
  }

  leave() {
    this.$emit('leave');
  }
}
