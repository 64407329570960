
import { Component, Vue } from 'vue-property-decorator';
import { usersService } from '@/services/users.service';
import { User } from '@/models/user';
import { downloadTermsAndConditionsPdf } from '@/utils/misc';
import { currentLocale } from '@/utils/i18n';

/**
 * Shows the terms and conditions for the user to accept them.
 */
@Component
export default class TermsAgreementModal extends Vue {
  private rememberDecision = false;
  private termsHtmlContent = '';

  private mounted() {
    this.$spinner.showSpinner();
    usersService
      .getTermsAndConditionsHtmlContent(currentLocale())
      .then((html) => this.termsHtmlContent = html)
      .finally(() => this.$spinner.removeSpinner());
  }

  private async acceptTermAgreement() {
    if (this.rememberDecision) {
      this.$spinner.showSpinner();

      try {
         await usersService.acceptTermsAndConditions(true);
      } catch (e: any) {
        this.$buefy.dialog.alert({
          message: `<p>${this.$i18n.t('terms.notify_failed')}</p><p><b>${e.message}</b></p>`,
          onConfirm: () => downloadTermsAndConditionsPdf(),
        });
      } finally {
        this.$spinner.removeSpinner();
      }
    }

    await usersService.acceptTermsAndConditions(false);
    this.$emit('close');
  }

  private async logout() {
    this.$spinner.showSpinner();
    await usersService.store.update({} as User);
    await this.$auth.logout().finally(() => this.$spinner.removeSpinner());
  }

  private get user(): User {
    return usersService.store.current();
  }
}
