// --------------------------------------------------------------------------------
// <copyright file="color.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import ColorAPI from 'color';
import { iterable } from './array';
import { BendGanttSpeedFlowStates } from '@/models/Charts/chartsData';

const colors = [
  '#5470c6',
  '#91cc75', // green
  '#fac858', // orange
  '#ee6666', // red salmon
  '#73c0de',
  '#3ba272',
  '#fc8452',
  '#9a60b4',
  '#ea7ccc',
  '#73c0de',
  '#bf6dbf',
  '#48879f',
  '#66b774',
  '#f6765b',
  '#c1ca68',
  '#6498d2',
  '#fba655',
  '#c4638d',
];

export const getColor: (index: number, brightness: number) => string = (
  index: number,
  brightness: number,
) => {
  const color = colors[index % colors.length];
  return iterable(3)
    .map((n) => color.substring(1 + n * 2, 1 + (n + 1) * 2))
    .map((c) => parseInt(c, 16) + brightness)
    .map((c) => Math.min(255, Math.max(0, c)))
    .map((c) => c.toString(16))
    .reduce((a, b) => a + b, '#');
};

const gradient = [
  '#f07a63',
  '#f38d60',
  '#f5a15e',
  '#f8b45b',
  '#fac858',
  '#e5c95e',
  '#d0ca64',
  '#bbca69',
  '#a6cb6f',
  '#91cc75',
];

export { gradient };

export const powerLevelColors = [
  '#000000', // Unused, just to be able to do powerLevelColors[powerLevel] (1 based)
  '#696969',
  '#556b2f',
  '#8b4513',
  '#228b22',
  '#483d8b',
  '#008b8b',
  '#4682b4',
  '#9acd32',
  '#00008b',
  '#7f007f',
  '#8fbc8f',
  '#b03060',
  '#ff4500',
  '#ffa500',
  '#ffff00',
  '#7cfc00',
  '#00ff7f',
  '#dc143c',
  '#00ffff',
  '#0000ff',
  '#a020f0',
  '#da70d6',
  '#ff7f50',
  '#1e90ff',
  '#90ee90',
  '#add8e6',
  '#ff1493',
  '#7b68ee',
  '#ffe4b5',
  '#ffc0cb',
];

export enum Color {
  LightOrange = '#FAB57F',
  Orange = '#FFA500',
  DarkOrange = '#FF8C00',
  Red = '#FF3529',
  Brown = '#856341',
  Green = '#2BCF54',
  DarkGrey = '#707070',
  Black = '#656565',
  PinkRed = '#E63148',
  Purple = '#ba87f9',
  Blue = '#768cd1',
  BlueLight = '#51A7F9',
  PurpleLight = '#763E9B',
  Gray = '#999999',
  GreenLight = '#6FC040',
  YellowLight = '#F5D427',
}

export function getStateColour(state: string): string {
  if (state.startsWith('wait')) {
    return Color.Brown;
  }
  switch (state) {
    case 'offline':
      return Color.DarkGrey;
    case 'work':
      return Color.Green;
    case 'bend':
      return Color.Green;
    case 'error':
      return Color.Red;
    case 'stop':
      return Color.DarkOrange;
    default:
      return Color.LightOrange;
  }
}

export function getBendFlowSpeedColor(state: string): string {
  switch (state) {
    case BendGanttSpeedFlowStates.HighFlow:
      return Color.GreenLight;
    case BendGanttSpeedFlowStates.LowFlow:
      return Color.YellowLight;
    case BendGanttSpeedFlowStates.NoFlow:
      return Color.Gray;
    case BendGanttSpeedFlowStates.Processing:
      return '#EFE0C7';
  }
  return '';
}

export function getBendStateColor(state: string): string {
  switch (state) {
    case 'automatic':
      return Color.BlueLight;
    case 'semi-automatic':
      return Color.PurpleLight;
    case 'non-productive':
      return Color.Gray;
  }
  return '';
}

export function getStateCss(state: string): string {
  return `color: ${getStateColour(state)}`;
}

export function poStatusToColor(status: string): string {
  switch (status) {
    case 'Planned':
      return getColor(0, 0);
    case 'Firm Planned':
      return getColor(1, 0);
    case 'Released':
      return getColor(2, 0);
    case 'Finished':
      return getColor(3, 0);
    case 'Prepared':
      return getColor(4, 0);
    case 'In Shop Floor':
      return getColor(5, 0);
    case 'In Progress': // Only for POs
      return getColor(6, 0);
    case 'In Progress (remaining)': // Used instead of 'In Progress' for POLs
      return getColor(11, 0);
    case 'In Progress (done)': // Used instead of 'In Progress' for POLs
      return getColor(13, 0);
    case 'Produced':
      return getColor(7, 0);
    default:
      return getColor(8, 0);
  }
}

export enum EventColor {
  Ok = '#91cc75',
  Warning = '#fac858',
  Error = '#ee6666',
}

export enum LevelColor {
  // These colors must match the ones in HealthBar's $level-colors
  A = '#26a239',
  B = '#32d74b',
  C = '#ffcc00',
  D = '#ff7300',
  E = '#ff453a',
}

export const BEND_SUMMARY_BASE_COLOR = '#28c76f';

export function createPaletteByLightness(
  baseColor: string,
  colorAmount: number,
  lowerLightnessBound = -0.25,
  upperLightnessBound = 0.25,
): string[] {
  const color = ColorAPI(baseColor);
  const lightnessBoundGap = upperLightnessBound - lowerLightnessBound;
  return iterable(colorAmount).map((index) =>
    color.lighten(lowerLightnessBound + (lightnessBoundGap * index) / colorAmount).hex(),
  );
}
